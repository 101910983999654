import styled from '@emotion/styled'
import { TripleWordCover } from 'assets/images';

export const Container = styled.div`
  display: inline-flex;
  position: absolute;
  width: 100%;
  height: 300px;
  z-index: 4;

  .rays {
    position: absolute;
    top: 0;
    left: 0;
    width: 600px;
    margin-top: -50%;
    margin-left: -45px;
    animation:spin 4s linear infinite;
    z-index: 3;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }
`;

export const TripleBonusWrapper = styled.div`
  z-index: 4;
  width: 450px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  top: 200px;
  opacity: 0;

  &.win {
    animation: move-top 1s forwards;
  }

  @keyframes move-top {
    to {
      top: 0;
      opacity: 1;
    }
  }

  .triple-bonus-word {
    z-index: 4;
    width: 100%;
    height: 55px;
    background-image: url(${TripleWordCover});
    background-size: 665px 95px;
    background-repeat: no-repeat;
    background-position: -100px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 10000vw;
  margin-left: -3000vw;
`;

export const OneMoreSevenContainer = styled.img`
  position: absolute;
  top: 205px;
  width: 100%;
  height: 66px;
  left: 0;
  right: 0;
  margin: auto;
`